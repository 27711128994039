import { ButtonGroup, Button } from 'react-bootstrap';
import styled from 'styled-components';
import BasicSpinner from './BasicSpinner';
import { extraLight, light, off } from '../theme/colors';

const StyledButtonGroup = styled(ButtonGroup)`
  background-color: ${extraLight};
  border-radius: 30px !important;
  padding: 4px;
`;

const StyledToggleButton = styled(Button)`
  border-radius: 30px !important;
  margin: 0;
  padding: 6px 20px;

  &.btn-secondary {
    border-color: ${extraLight};
    background-color: ${extraLight};
    color: ${light};

    &:hover {
      border-color: ${extraLight} !important;
      background-color: ${extraLight} !important;
      color: ${off} !important;
    }
  }

  &:first-child {
    margin-right: 1px;
  }
`;

const SpinnerWrapper = styled.div`
  position: relative;
  top: 1px;
`;

const Toggle = ({
  switchOn,
  isLoading,
  isSaving,
  handleSwitchChange,
  onLabel = 'ON',
  offLabel = 'OFF',
}) => (
  <div className="d-flex align-items-center">
    <StyledButtonGroup className="mt-2">
      <StyledToggleButton
        variant={!switchOn ? 'primary' : 'secondary'}
        onClick={() => handleSwitchChange(false)}
        disabled={isLoading || isSaving}>
        {onLabel}
      </StyledToggleButton>
      <StyledToggleButton
        variant={switchOn ? 'primary' : 'secondary'}
        onClick={() => handleSwitchChange(true)}
        disabled={isLoading || isSaving}>
        {offLabel}
      </StyledToggleButton>
    </StyledButtonGroup>
    {(isLoading || isSaving) && (
      <SpinnerWrapper>
        <BasicSpinner />
      </SpinnerWrapper>
    )}
  </div>
);

export default Toggle;
