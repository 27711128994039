import InformationPanel from './InformationPanel';
import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../context/AppContext';
import { updateUserDetails } from '../../../api/Optimise';
import Toggle from '../../Toggle';

const SimulationModeSettings = ({ loadUser, setShowError }) => {
  const { userDetails, userSub, showSimulationMode } = useContext(AppContext);
  const [switchOn, setSwitchOn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!!userDetails) {
      setSwitchOn(userDetails?.simulation_mode);
      setIsLoading(false);
    }
  }, [userDetails]);

  const handleSwitchChange = async newState => {
    setSwitchOn(newState);
    setIsSaving(true);
    updateUserDetails(
      {
        battery_soc_lower_limit: userDetails.battery_details.soc_lower_limit,
        battery_soc_upper_limit: 100,
        simulation_mode: newState,
      },
      userSub
    )
      .then(() => {
        setIsSaving(false);
        loadUser();
      })
      .catch(e => {
        console.log(e);
        setIsSaving(false);
        setShowError(true);
      });
  };

  const currentState = switchOn ? 'OFF' : 'ON';

  const disableText = !switchOn ? (
    <>
      Loop Optimise is currently <b>ON</b>. You can turn <b>OFF</b> Loop
      Optimise using the button below - this will stop Loop Optimise controlling
      your battery. We will not be able to show your battery status or any
      savings while Loop Optimise is <b>OFF</b>.
    </>
  ) : (
    <>
      Loop Optimise is currently <b>OFF</b>. You can turn <b>ON</b> Loop
      Optimise using the button below - this will allow Loop Optimise to control
      your battery. We are not able to show your battery status or any savings
      while Loop Optimise is <b>OFF</b>.
    </>
  );

  return (
    <InformationPanel
      title={
        showSimulationMode
          ? 'Simulation mode'
          : `Loop Optimise Is ${currentState}`
      }>
      <li>
        {showSimulationMode
          ? 'Turning simulation mode on stops Loop Optimise controlling your battery. You will see recommended actions on "Today" but these will not be sent.'
          : disableText}
        <Toggle
          switchOn={switchOn}
          isLoading={isLoading}
          isSaving={isSaving}
          handleSwitchChange={handleSwitchChange}
        />
      </li>
    </InformationPanel>
  );
};

export default SimulationModeSettings;
