import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getUserDetails } from '../../api/Optimise';
import { AppContext } from '../../context/AppContext';
import getDateFromTimestamp from '../../utils/getDateFromTimestamp';

const Container = styled.div`
  margin-top: 24px;
`;

const Title = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #212529;
  margin-bottom: 5px;
  display: flex;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const DateSelector = styled.div`
  background-color: #eff2f6;
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;
  }

  input {
    background: transparent;
    border: none;
    font-size: 14px;
    width: 100%;
    cursor: pointer;

    @media (min-width: 576px) {
      font-size: 16px;
    }
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 15px;
  pointer-events: none;
  z-index: 1;
`;

const DatePickerLayout = ({ children }) => {
  const { timestamp } = useParams();
  const location = useLocation();
  const [dateRange, setDateRange] = useState({minDate: new Date(), maxDate: new Date(), isDisabled: false});

  const {
    selectedDate,
    setSelectedDate,
    userDetails,
    setUserDetails,
    userSub,
    datePickerWarning,
  } = useContext(AppContext);

  const getMaxDate = endDateTime => {
    const maxDate = getDateFromTimestamp(endDateTime);
    const today = moment().startOf('day').toDate();
    return maxDate >= today ? moment().subtract(1, 'days').toDate() : maxDate;
  };

  useEffect(() => {
    if (timestamp) {
      const date = moment.unix(timestamp).toDate();
      setSelectedDate(date);
    }

    getUserDetails(userSub).then(user => {
      setUserDetails(user);
      if (!timestamp) {
        const date = user.plant_data_range.end_datetime
          ? getMaxDate(user.plant_data_range.end_datetime)
          : moment().subtract(1, 'days').toDate();
        setSelectedDate(date);
      }
    });
  }, []);

  useLayoutEffect(() => {
    if(userDetails) {
      const isSavingsPage = location.pathname.includes('/app/savings/');
      const {minDate, maxDate} = isSavingsPage 
      ? { 
          minDate: new Date(userDetails?.analytics_data_range.start_date || new Date()), 
          maxDate: new Date(userDetails?.analytics_data_range.end_date || new Date()) 
      } 
      : {
          minDate: getDateFromTimestamp(userDetails?.plant_data_range.start_datetime),
          maxDate:getDateFromTimestamp(userDetails?.plant_data_range.end_datetime)
      };
      const isDisabled = isSavingsPage ? false : !userDetails?.plant_data_range;

      setDateRange({minDate, maxDate, isDisabled});
    }
  }, [userDetails, location]);

  return (
    <div>
      {
        <Container>
          {!!datePickerWarning && datePickerWarning}
          <Title>Date</Title>
          <DateSelector>
            <DatePicker
              selected={selectedDate}
              onChange={setSelectedDate}
              dateFormat="dd/MM/yy"
              disabled={dateRange.isDisabled}
              maxDate={dateRange.maxDate}
              minDate={dateRange.minDate}
            />
            <StyledFontAwesomeIcon icon={faChevronDown} />
          </DateSelector>
        </Container>
      }

      {children}
    </div>
  );
};

export default DatePickerLayout;
