import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import {
  cheap,
  peak,
  white,
  day,
  light,
  black,
  extraLight,
} from '../../../theme/colors';
import { useEffect, useState } from 'react';
import {
  addRateCategoriesToRecords,
  RateCategories,
} from '../../../utils/savingsUtils';
import formatMoneyValue from '../../../utils/formatMoneyValue';
import hasRate from '../../../utils/hasRate';

const colorMap = {
  [RateCategories.CHEAP]: cheap,
  [RateCategories.DAY]: day,
  [RateCategories.PEAK]: peak,
};

const Chart = ({
  data,
  dataKey,
  barColor,
  yAxisLabel,
  xAxisLabel,
  dataAvailable,
  direction,
}) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (data?.length) {
      const dataWithCategoriees = addRateCategoriesToRecords(data, direction);
      setChartData(dataWithCategoriees);
    }
  }, [data]);

  const legendData = [];
  if (hasRate(chartData, direction, RateCategories.CHEAP)) {
    legendData.push({
      name: RateCategories.DAY,
      symbol: { fill: 'transparent', stroke: black },
    });
    legendData.push({ name: RateCategories.CHEAP, symbol: { fill: cheap } });
  }
  if (hasRate(chartData, direction, RateCategories.PEAK)) {
    legendData.push({ name: RateCategories.PEAK, symbol: { fill: peak } });
  }

  const highestDayEarnings = chartData?.reduce(
    (acc, row) => (row[dataKey] > acc ? row[dataKey] : acc),
    0
  );

  const boundaryData = chartData?.map(row => ({
    time: row.time,
    boundary: row[`${direction}_rate_category`],
    color: colorMap[row[`${direction}_rate_category`]],
    value:
      row[`${direction}_rate_category`] == 'Cheap rate' ||
      row[`${direction}_rate_category`] == 'Peak rate'
        ? highestDayEarnings
        : 0,
  }));

  const placeholderData = [
    { time: '00:00', [dataKey]: 0 },
    { time: '03:00', [dataKey]: 0 },
    { time: '06:00', [dataKey]: 0 },
    { time: '09:00', [dataKey]: 0 },
    { time: '12:00', [dataKey]: 0 },
    { time: '15:00', [dataKey]: 0 },
    { time: '18:00', [dataKey]: 0 },
    { time: '21:00', [dataKey]: 0 },
    { time: '00:00', [dataKey]: 0 },
  ];

  const yTickValues = dataAvailable ? undefined : [0, 10, 20, 30, 40];
  const yDomain = dataAvailable ? undefined : [0, 40];

  return (
    <div style={{ position: 'relative' }}>
      <VictoryChart
        domainPadding={10}
        width={648}
        height={420}
        padding={{ top: 30, right: 50, bottom: 71, left: 60 }}
        domain={{ y: yDomain }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => {
              if (datum[dataKey]) {
                return `${formatMoneyValue(datum[dataKey])}`;
              } else if (datum.boundary) {
                return datum.boundary;
              }
            }}
            labelComponent={
              <VictoryTooltip
                cornerRadius={4}
                pointerLength={10}
                style={{ fontSize: 16 }}
                flyoutStyle={{
                  fill: white,
                  stroke: extraLight,
                }}
                flyoutPadding={{ top: 4, bottom: 5, left: 8, right: 8 }}
              />
            }
          />
        }>
        <VictoryLegend
          x={50}
          y={0}
          orientation="horizontal"
          gutter={20}
          style={{ title: { fontSize: 14 } }}
          data={legendData}
        />
        <VictoryAxis
          label={xAxisLabel}
          tickFormat={t => {
            if (
              [
                '00:00',
                '03:00',
                '06:00',
                '09:00',
                '12:00',
                '15:00',
                '18:00',
                '21:00',
              ].includes(t)
            ) {
              return t;
            }
            return '';
          }}
          style={{
            axis: { stroke: '#afb6b9' },
            axisLabel: {
              fontSize: 16,
              padding: 50,
            },
            ticks: { stroke: 'transparent', size: 5 },
            tickLabels: {
              fontSize: 14,
              padding: 5,
              angle: -90,
              textAnchor: 'end',
            },
            grid: { stroke: 'transparent' },
          }}
          offsetY={70}
        />
        <VictoryAxis
          dependentAxis
          label={`${yAxisLabel} (p)`}
          tickValues={yTickValues}
          style={{
            axis: { stroke: 'transparent' },
            ticks: { stroke: 'transparent' },
            tickLabels: {
              fill: 'black',
              fontSize: 14,
              padding: 5,
            },
            grid: {
              stroke: { light },
              opacity: 0.3,
              strokeWidth: 0.5,
              strokeDasharray: '0',
            },
            axisLabel: {
              fontSize: 16,
              padding: 40,
              textAnchor: 'middle',
              fontWeight: 'bold',
              fill: barColor,
            },
          }}
        />
        {/* Boundary Data Bar */}
        {dataAvailable && (
          <VictoryBar
            data={boundaryData}
            x="time"
            y="value"
            barWidth={12}
            style={{ data: { fill: ({ datum }) => datum.color } }}
          />
        )}
        {/* Earnings Data Bar */}
        <VictoryBar
          data={dataAvailable ? chartData : placeholderData}
          x="time"
          y={dataKey}
          barWidth={10}
          style={{ data: { fill: barColor } }}
        />
        {!dataAvailable && (
          <>
            <VictoryLabel
              textAnchor="middle"
              style={{ fontSize: 15, fontWeight: 'bold' }}
              x={'50%'}
              y={192}
              text={`Not enough data to show ${yAxisLabel}.`}
            />
          </>
        )}
      </VictoryChart>
    </div>
  );
};

export default Chart;
