import { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import AppLayout from '../ui/Layout/AppLayout';
import LoginCard from '../ui/LoginCard';
import {
  AddressContainer,
  ElectricVehicleContainer,
  IntroductionContainer,
  ManualAddressContainer,
  PvSystemContainer,
  SolarDiverterContainer,
  SolarDiverterWarningContainer,
  ConnectingContainer,
  // ErrorContainer,
  TariffContainer,
  ConnectedContainer,
  EquipmentDetailsContainer,
} from './Onboarding';
import { OnboardingContext } from '../context/OnboardingContext';
import OnboardingProgress from '../ui/OnboardingProgress';
import AlphaessConnectionContainer from './Onboarding/Connection/AlphaessConnectionContainer';
import GivEnergyConnectionContainer from './Onboarding/Connection/GivEnergyConnectionContainer';
import GrowattConnectionContainer from './Onboarding/Connection/GrowattConnectionContainer';
import InstagenConnectionContainer from './Onboarding/Connection/InstagenConnectionContainer';
import SunsynkConnectionContainer from './Onboarding/Connection/SunsynkConnectionContainer';
import SolisConnectionContainer from './Onboarding/Connection/SolisConnectionContainer';
import MockInverterConnectionContainer from './Onboarding/Connection/MockInverterConnectionContainer';
import SolarPanelsContainer from './Onboarding/SolarPanelsContainer';
import SolarPanelGroupsContainer from './Onboarding/SolarPanelGroupsContainer';
import ExportLimitContainer from './Onboarding/ExportLimitContainer';

const OnboardingContainer = () => {
  const { progressPercentage } = useContext(OnboardingContext);
  return (
    <AppLayout backgroundTint="blue" showSignOut={true}>
      <LoginCard>
        {progressPercentage > 0 && (
          <OnboardingProgress percentage={progressPercentage} />
        )}
        <Switch>
          <Route path="/onboarding" component={IntroductionContainer} exact />
          <Route
            path="/onboarding/address"
            component={AddressContainer}
            exact
          />
          <Route path="/onboarding/manual-address" exact>
            <ManualAddressContainer />
          </Route>
          <Route path="/onboarding/tariff" exact>
            <TariffContainer />
          </Route>
          <Route path="/onboarding/pv-system" exact>
            <PvSystemContainer />
          </Route>
          <Route path="/onboarding/solar-panels" exact>
            <SolarPanelsContainer />
          </Route>
          <Route path="/onboarding/solar-panels/details" exact>
            <SolarPanelGroupsContainer />
          </Route>
          <Route path="/onboarding/solar-diverter" exact>
            <SolarDiverterContainer />
          </Route>
          <Route path="/onboarding/solar-diverter-warning" exact>
            <SolarDiverterWarningContainer />
          </Route>
          <Route path="/onboarding/electric-vehicle" exact>
            <ElectricVehicleContainer />
          </Route>
          <Route path="/onboarding/equipment-details" exact>
            <EquipmentDetailsContainer />
          </Route>
          <Route path="/onboarding/export-limit" exact>
            <ExportLimitContainer />
          </Route>
          <Route path="/onboarding/connection/alphaess" exact>
            <AlphaessConnectionContainer />
          </Route>
          <Route path="/onboarding/connection/givenergy" exact>
            <GivEnergyConnectionContainer />
          </Route>
          <Route path="/onboarding/connection/growatt" exact>
            <GrowattConnectionContainer />
          </Route>
          <Route path="/onboarding/connection/instagen" exact>
            <InstagenConnectionContainer />
          </Route>
          <Route path="/onboarding/connection/sunsynk" exact>
            <SunsynkConnectionContainer />
          </Route>
          <Route path="/onboarding/connection/solis" exact>
            <SolisConnectionContainer />
          </Route>
          <Route path="/onboarding/connection/mock-inverter" exact>
            <MockInverterConnectionContainer />
          </Route>
          <Route path="/onboarding/connecting" exact>
            <ConnectingContainer />
          </Route>
          <Route path="/onboarding/connected" exact>
            <ConnectedContainer />
          </Route>

          {/*<Route
              path="/onboarding/error"
              component={ErrorContainer}
              exact
            />
            <Route>
              <Redirect to="/not-found" />
            </Route> */}
        </Switch>
      </LoginCard>
    </AppLayout>
  );
};

export default OnboardingContainer;
