import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import textOrPlaceholder from '../../../utils/textOrPlaceholder';
import {
  addRateCategoriesToRecords,
  extractRates,
  RateCategories,
} from '../../../utils/savingsUtils';
import { TariffDirection } from '../../../api/Optimise';
import {
  dark,
  light,
  primary,
  secondary,
  tertiaryGradient,
  white,
} from '../../../theme/colors';

const StyledContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  background-image: ${tertiaryGradient};
`;

const StyledTitle = styled.h3`
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
  letter-spacing: 1.5px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const StyledTableContainer = styled.div`
  background-color: white;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`;

const StyledTh = styled.th`
  padding: 20px 10px 10px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.color};
  border-bottom: 1px solid ${light};

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const StyledTd = styled.td`
  padding: 12px;
  border: none;
  font-size: 12px;
  text-align: ${props => (props.center ? 'center' : 'left')};
  border-bottom: 1px solid ${light};

  tr:last-child & {
    border-bottom: none;
  }

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const StyledRateLabel = styled.span`
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: ${props => props.bgColor};
  border: 1px solid ${props => props.borderColor ?? white};
  color: ${props => props.color ?? dark};
  padding: 4px 8px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: bold;
  font-size: 10px;

  @media (min-width: 576px) {
    font-size: 12px;
  }
`;

const StyledRateDescription = styled.p`
  margin-top: 8px;
  font-size: 12px;

  @media (min-width: 576px) {
    font-size: 12px;
  }
`;

const getRowContent = key => {
  switch (key) {
    case RateCategories.DAY:
      return {
        heading: 'Day Rate',
        description: 'Average cost electricity.',
        color: dark,
        bgColor: '#fff',
        borderColor: dark,
      };
    case RateCategories.CHEAP:
      return {
        heading: 'Cheap Rate',
        description:
          'Charge the battery when the import rate is at its lowest.',
        color: '#fff',
        bgColor: '#3cba92',
        borderColor: '#3cba92',
      };
    case RateCategories.PEAK:
      return {
        heading: 'Peak Rate',
        description:
          'Consume power from the battery when the import rate is highest for biggest savings.',
        color: dark,
        bgColor: '#f7d22d',
        borderColor: '#f7d22d',
      };
  }
};

const TariffKey = ({ savingsChartData }) => {
  const [rates, setRates] = useState(null);

  useEffect(() => {
    if (savingsChartData) {
      const dataWithImportCategoriees = addRateCategoriesToRecords(
        savingsChartData,
        TariffDirection.IMPORT
      );
      const dataWithExportCategories = addRateCategoriesToRecords(
        dataWithImportCategoriees,
        TariffDirection.EXPORT
      );
      const rates = extractRates(dataWithExportCategories);

      setRates(rates);
    }
  }, [savingsChartData]);

  const printTariffRate = rate => {
    let rateText = `${textOrPlaceholder(rate.minRate, rate.minRate)}`;

    if (rate.minRate != rate.maxRate) {
      rateText += ` - ${textOrPlaceholder(rate.maxRate, rate.maxRate)}`;
    }

    if (rate.minRate) rateText += ' p/kWh';

    return rateText;
  };

  return (
    <StyledContainer>
      <StyledTitle>Tariff Rates Explained</StyledTitle>
      <StyledTableContainer>
        <StyledTable>
          <thead>
            <tr>
              <StyledTh></StyledTh>
              <StyledTh color={primary}>Import</StyledTh>
              <StyledTh color={secondary}>Export</StyledTh>
            </tr>
          </thead>
          <tbody>
            {rates &&
              Object.values(RateCategories).map(category => {
                const importRate = rates.import[category];
                const exportRate = rates.export[category];
                const content = getRowContent(category);
                if (
                  importRate.minRate ||
                  exportRate.minRate ||
                  savingsChartData?.length === 0
                ) {
                  return (
                    <tr>
                      <StyledTd>
                        <StyledRateLabel
                          color={content.color}
                          bgColor={content.bgColor}
                          borderColor={content.borderColor}>
                          {content.heading}
                        </StyledRateLabel>
                        <StyledRateDescription>
                          {content.description}
                        </StyledRateDescription>
                      </StyledTd>
                      <StyledTd center>{printTariffRate(importRate)}</StyledTd>
                      <StyledTd center>{printTariffRate(exportRate)}</StyledTd>
                    </tr>
                  );
                }
              })}
          </tbody>
        </StyledTable>
      </StyledTableContainer>
    </StyledContainer>
  );
};

export default TariffKey;
