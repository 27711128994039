import InformationPanel from './InformationPanel';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../../context/AppContext';
import { updateOptimiserSettings } from '../../../api/Optimise';
import Toggle from '../../Toggle';

const SelfConsumptionModeSettings = ({
  loadOptimiserSettings,
  setShowError,
}) => {
  const { optimiserSettings, userSub } = useContext(AppContext);
  const [switchOn, setSwitchOn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!!optimiserSettings) {
      setSwitchOn(optimiserSettings?.battery_options.self_consumption_mode);
      setIsLoading(false);
    }
  }, [optimiserSettings]);

  const handleSwitchChange = async () => {
    const invertedSwitch = !switchOn;
    setSwitchOn(invertedSwitch);
    setIsSaving(true);
    await updateOptimiserSettings(
      {
        battery_options: { self_consumption_mode: invertedSwitch },
      },
      userSub
    )
      .then(() => {
        loadOptimiserSettings();
      })
      .catch(e => {
        console.error(e);
        setShowError(true);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <InformationPanel title="Self-consumption mode">
      <li>
        Loop's default mode optimises for cash savings. Self-consumption mode
        enables your home to rely more on your battery and less on the grid
        during off peak times. This will lead to slightly smaller savings.
        <Toggle
          switchOn={!switchOn}
          isLoading={isLoading}
          isSaving={isSaving}
          handleSwitchChange={handleSwitchChange}
        />
      </li>
    </InformationPanel>
  );
};

export default SelfConsumptionModeSettings;
