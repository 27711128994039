import * as Yup from 'yup';
import BaseConnectionContainer from './BaseConnectionContainer';

const MockInverterConnectionContainer = () => {
  const fields = [
    {
      name: 'api_username',
      label: 'Mock inverter username',
      validation: Yup.string().required(
        'Please enter your mock inverter username'
      ),
    },
    {
      name: 'api_password',
      label: 'Mock inverter password',
      type: 'password',
      validation: Yup.string().required(
        'Please enter your mock inverter password'
      ),
    },
  ];

  return (
    <BaseConnectionContainer fields={fields} inverterManufacturer="Mock" />
  );
};

export default MockInverterConnectionContainer;
