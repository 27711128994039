import awsmobile from '../config/aws-exports';
import apiRequest from '../utils/apiRequest';

/**
 * Constant representing the kilowatts generated per solar panel.
 *
 * This value is used to calculate the total energy output of the solar panels
 * based on the number of panels a user has.
 */
export const KW_PER_PANEL = 0.4;

/**
 * Enum for hardware types.
 *
 * Defines the available hardware types that can be used in the onboarding process or hardware-related API requests.
 *
 * @enum {string}
 * @property {string} INVERTER - Represents the "inverter" hardware type.
 * @property {string} BATTERY - Represents the "battery" hardware type.
 */
export const HardwareTypes = {
  INVERTER: 'inverter',
  BATTERY: 'battery',
};

/**
 * Represents the direction of a tariff.
 *
 * @constant
 * @type {Object}
 * @property {string} IMPORT - Represents importing tariffs.
 * @property {string} EXPORT - Represents exporting tariffs.
 */
export const TariffDirection = {
  IMPORT: 'import',
  EXPORT: 'export',
};

/**
 * @constant {string} tdasCampaignId
 * @description A unique identifier for the TDAS (Turn Down & Save) campaign.
 * This value is used throughout the application to reference the campaign in APIs,
 * analytics, or campaign-specific logic.
 */
export const tdasCampaignId = 'LO_TDAS';

/**
 * Creates user battery solar details.
 *
 * Sends a POST request to create the user's battery solar details.
 *
 * @async
 * @function createUserBatterySolarDetails
 * @param {Object} details - The battery solar details to be saved.
 * @returns {Promise<any>} - A promise that resolves to the response from the API.
 */
export const createUserBatterySolarDetails = async details =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/onboarding/user-battery-solar-details`,
    null,
    'POST',
    details
  );

/**
 * Creates user trace.
 *
 * Sends a POST request to log the user's progress through the onboarding process.
 *
 * @async
 * @function createUserTrace
 * @param {string} stepCompleted - The step of the onboarding process that has been completed.
 * @param {Object} [data={}] - Additional data related to the completed step.
 * @returns {Promise<any>} - A promise that resolves to the response from the API.
 */
export const createUserTrace = async (stepCompleted, data = {}) =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/onboarding/user-trace`,
    null,
    'POST',
    { step_completed: stepCompleted, data }
  );

/**
 * Retrieves supported hardware.
 *
 * Sends a GET request to retrieve the list of supported hardware for a given hardware type.
 *
 * @async
 * @function getSupportedHardware
 * @param {string} hardwareType - The type of hardware for which to retrieve support information. Can be either "inverter" or "battery".
 * @returns {Promise<any>} - A promise that resolves to the response from the API containing the supported hardware.
 */
export const getSupportedHardware = async hardwareType =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/onboarding/supported-hardware?hardware_type=${hardwareType}`
  );

/**
 * Retrieves 24 hours of system data.
 *
 * Retrieves 24 hours of system data for a given user.
 *
 * @async
 * @function getTwentyHoursOfSystemData
 * @param {string|null} [userSub=null] - Optional user sub identifier.
 * @returns {Promise<any>} - A promise that resolves to the system data.
 */
export const getTwentyHoursOfSystemData = async (userSub = null) =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/24-hours-of-system-data`,
    userSub
  );

/**
 * Retrieves analytics data.
 *
 * Fetches analytics data for a given date.
 *
 * @async
 * @function getAnalyticsData
 * @param {string} date - The date for which to retrieve analytics data (format: YYYY-MM-DD).
 * @param {string|null} [userSub=null] - Optional user sub identifier.
 * @returns {Promise<any>} - A promise that resolves to the analytics data.
 */
export const getAnalyticsData = async (date, userSub = null) =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/analytics-data?analysis_date=${date}`,
    userSub
  );

/**
 * @async
 * @param {string} direction - The direction of the tariff, one of the `TariffDirection` properties.
 * @param {string} regionId - The region identifier for which to fetch supported tariffs. As returned by the Location service getRegion call.
 * @return {Promise<any>} - A promise that resolves to the supported tariffs. - A promise that resolves to the supported tariffs.
 */
export const getSupportedTariffs = async (direction, regionId) =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/onboarding/supported-tariffs?direction=${direction}&region=${regionId}`
  );

/**
 * Retrieves the current behaviour forecast.
 *
 * Fetches the current behaviour forecast for the user.
 *
 * @async
 * @function getCurrentBehaviourForecast
 * @param {string|null} [userSub=null] - Optional user sub identifier.
 * @returns {Promise<any>} - A promise that resolves to the behaviour forecast data.
 */
export const getCurrentBehaviourForecast = async (userSub = null) =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/current-behaviour-forecast`,
    userSub
  );

/**
 * Retrieves user details.
 *
 * Retrieves the user's details from the server.
 *
 * @async
 * @function getUserDetails
 * @param {string|null} [userSub=null] - Optional user sub identifier.
 * @returns {Promise<any>} - A promise that resolves to the user details.
 */
export const getUserDetails = async (userSub = null) =>
  await apiRequest(`${awsmobile.optimise_endpoint_auth}/user-details`, userSub);

/**
 * Updates user details.
 *
 * Sends a POST request to update the user's details.
 *
 * @async
 * @function updateUserDetails
 * @param {Object} details - The user details to be updated.
 * @param {string|null} [userSub=null] - Optional user sub identifier.
 * @returns {Promise<any>} - A promise that resolves to the server response.
 */
export const updateUserDetails = async (details, userSub = null) =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/user-details`,
    userSub,
    'POST',
    details
  );

/**
 * Creates inverter authentication details.
 *
 * Sends a PUT request to create or update the user's inverter authentication details.
 *
 * @async
 * @function createInverterAuthentication
 * @param {Object} details - The inverter authentication details to be updated.
 * @returns {Promise<any>} - A promise that resolves to the server response.
 */
export const createInverterAuthentication = async details =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/inverter-authentication`,
    null,
    'PUT',
    details
  );

/**
 * Retrieves user loop account.
 *
 * Sends a GET request to retrieve the user's loop account details.
 *
 * @async
 * @function getUserLoopAccount
 * @returns {Promise<any>} - A promise that resolves to the loop account details.
 */
export const getUserLoopAccount = async () =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/internal/user-details/loop-account`
  );

/**
 * Retrieves user mpan.
 *
 * Sends a GET request to retrieve the user's mpan.
 *
 * @async
 * @function getUserMpan
 * @returns {Promise<any>} - A promise that resolves to the user mpan.
 */
export const getUserMpan = async () =>
  await apiRequest(`${awsmobile.optimise_endpoint_auth}/internal/user/mpan`);

/**
 * Retrieves all campaigns.
 *
 * Sends a GET request to retrieve all campaigns.
 *
 * @async
 * @function getCampaigns
 * @returns {Promise<any>} - A promise that resolves to the list of all campaigns.
 */
export const getGridCampaigns = async () =>
  await apiRequest(`${awsmobile.optimise_endpoint_auth}/grid-event/campaigns`);

/**
 * Retrieves all user campaigns.
 *
 * Sends a GET request to retrieve all campaigns for a specific user.
 *
 * @async
 * @function getUserCampaigns
 * @returns {Promise<any>} - A promise that resolves to the list of user-specific campaigns.
 */
export const getUserGridCampaigns = async () =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/grid-event/campaigns/user`
  );

/**
 * Sets the user's campaign participation status.
 *
 * Sends a POST request to update the user's participation status for a specific campaign.
 *
 * @async
 * @function setUserCampaigns
 * @param {string} campaignId - The ID of the campaign to set for the user.
 * @param {boolean} [optIn=true] - The user's opt-in status for the campaign. Defaults to `true`.
 * @returns {Promise<any>} - A promise that resolves to the response of the API request.
 */
export const setUserGridCampaigns = async (campaignId, optIn = true) =>
  // TODO: Remove the query params when the api accepts a post body in future
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/grid-event/campaigns/user?campaign_id=${campaignId}&opt_in=${optIn}`,
    null,
    'POST',
    { campaign_id: campaignId, opt_in: optIn }
  );

/**
 * Get all grid events for a campaign that a user is entering, these can be all
 * historic events in the past or future events that the user will be entering
 *
 * @async
 * @function getUserEvents
 * @param {string} campaignId - The ID of the campaign to set for the user.
 * @returns {Promise<any>} - A promise that resolves to the response of the API request.
 */
export const getUserGridEvents = async campaignId =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/grid-event/campaigns/user/entered-events?campaign_id=${campaignId}`
  );

/**
 * Get optimiser settings for a user
 *
 * Fetches the optimiser settings for a given user. This includes self-consumption mode settings.
 *
 * @param userSub
 * @returns {Promise<undefined|*>}
 */
export const getOptimiserSettings = async (userSub = null) =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/optimiser/settings`,
    userSub
  );

/**
 * Update optimiser settings for a user
 *
 * Sends a POST request to update the optimiser settings for a given user.
 *
 * @param optimiserSettings
 * @param userSub
 * @returns {Promise<undefined|*>}
 */
export const updateOptimiserSettings = async (optimiserSettings, userSub) =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/optimiser/settings`,
    userSub,
    'POST',
    optimiserSettings
  );

/**
 * Retrieves enabled system features.
 *
 * Sends a GET request to retrieve the list of enabled system features.
 *
 * @async
 * @function getEnabledSystemFeatures
 * @returns {Promise<any>} - A promise that resolves to the response from the API containing the enabled system features.
 */
export const getEnabledSystemFeatures = async () =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/enabled-system-features`
  );

/**
 * Retrieves historic system data.
 *
 * Sends a GET request to retrieve historic system data for a user within two timestamps.
 *
 * @async
 * @function getHistoricSystemData
 * @param {number} startTimestamp - The start timestamp in UTC.
 * @param {number} endTimestamp - The end timestamp in UTC.
 * @returns {Promise<any>} - A promise that resolves to the response from the API containing the historic system data.
 */
export const getHistoricSystemData = async (startTimestamp, endTimestamp, userSub = null) =>
  await apiRequest(
    `${awsmobile.optimise_endpoint_auth}/historic-system-data?start_timestamp_utc=${startTimestamp}&end_timestamp_utc=${endTimestamp}`, userSub
  );
