import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { getAnalyticsData, getUserDetails } from '../../api/Optimise';
import { AppContext } from '../../context/AppContext';
import withPageTitle from '../../hocs/withPageTitle';
import { useOnDateChange } from '../../hooks';
import ExportChart from '../../ui/Dashboard/Savings/ExportChart';
import ImportChart from '../../ui/Dashboard/Savings/ImportChart';
import SavingsSummary from '../../ui/Dashboard/Savings/SavingsSummary';
import TariffKey from '../../ui/Dashboard/Savings/TariffKey';
import InfoModal from '../../ui/Modal/InfoModal';
import WarningPanel from '../../ui/WarningPanel';
import {
  getSavingsTotal,
  transformAnalyticsDataForChart,
} from '../../utils/savingsUtils';

const SavingsContainer = () => {
  const history = useHistory();
  const [fetchedAnalyticsData, setFetchedAnalyticsData] = useState(false);
  const [fetchedLatestAnalyticsData, setFetchedLatestAnalyticsData] =
    useState(false);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [latestItem, setLatestItem] = useState(null);
  const [formattedDate, setFormattedDate] = useState(
    moment().subtract(1, 'days').format('YYYY-MM-DD')
  );
  const [showNoDataModal, setShowNoDataModal] = useState(false);
  const { userDetails, analyticsStartDate, userSub, setDatePickerWarning, setSelectedDate } =
    useContext(AppContext);

  const startDateIsToday = !!analyticsStartDate
    ? moment(analyticsStartDate).isSame(moment(), 'day')
    : true;

  const fetchAnalyticsData = useCallback(async date => {
    try {
      const data = await getAnalyticsData(date, userSub);
      setAnalyticsData(data);
    } catch (error) {
      if (error.status === 404) {
        setAnalyticsData([]);
      }
      console.error('Error fetching analytics data:', error);
    } finally {
      setFetchedAnalyticsData(true);
    }
  }, []);

  const fetchLatestItem = useCallback(async () => {
    const user = await getUserDetails(userSub);
    const end = moment
      .utc(user?.analytics_data_range.end_date)
      .format('YYYY-MM-DD');
    try {
      const data = await getAnalyticsData(end, userSub);
      !!data && setLatestItem(data[data.length - 1]);
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    } finally {
      setFetchedLatestAnalyticsData(false);
    }
  }, []);

  useEffect(() => {
    fetchAnalyticsData(formattedDate);
    fetchLatestItem();

    // Set up periodic refresh (e.g., every 5 minutes)
    const refreshInterval = setInterval(
      () => {
        fetchAnalyticsData(formattedDate);
        fetchLatestItem();
      },
      5 * 60 * 1000
    );

    return () => clearInterval(refreshInterval);
  }, [formattedDate, fetchAnalyticsData]);

  const { savings, savingsChartData } = useMemo(() => {
    if (!analyticsData) {
      return {};
    }
    return {
      savingsChartData: transformAnalyticsDataForChart(analyticsData),
      savings: getSavingsTotal(analyticsData),
    };
  }, [analyticsData, userDetails]);

  useEffect(() => {
    {
      if (startDateIsToday) {
        setDatePickerWarning(
          <>
            <WarningPanel
              title="Not enough data to show your Savings."
              body="Please wait 24 hours for the Loop Optimise calculations to be made."
              linkText="Why does Loop Optimise not have my data yet?"
              linkAction={() => setShowNoDataModal(true)}
            />

            <InfoModal
              show={showNoDataModal}
              onClose={() => setShowNoDataModal(false)}
              infoContent={
                <div>
                  Calculating relative savings can be a complex and
                  time-consuming process. For this reason, Loop Optimise
                  performs these calculations once a day (overnight) to provide
                  a summary of savings from the previous day. While this
                  approach may change in the future, a daily summary is
                  currently sufficient to show how much was saved using Loop
                  Optimise. <br />
                  <br />
                  So, when you first sign up, you may need to wait up to 24
                  hours for daily savings to be available.
                </div>
              }
            />
          </>
        );
      } else {
        setDatePickerWarning(undefined);
      }
    }
  }, [startDateIsToday]);

  const onSelectDate = useCallback(
    date => {
      let formattedDate = moment(date).format('YYYY-MM-DD');
      if(userDetails) {
        // NOTE: this stops the savings page being able to have a date before the range
        // when navigating to it after setting a different date.
        if(moment(userDetails.analytics_data_range.start_date) > moment(date)) {
          setSelectedDate(moment(userDetails.analytics_data_range.start_date).toDate())
          return;
        }
      }
      history.push(`/app/savings/${moment(date).unix()}`);
      setFormattedDate(formattedDate);
    },
    [history, userDetails]
  );

  useOnDateChange(onSelectDate);

  return (
    <>
      <SavingsSummary
        savings={savings}
        total={latestItem?.running_total_saving_against_dumb_battery}
        fetchedAnalyticsData={fetchedAnalyticsData}
        fetchedLatestAnalyticsData={fetchedLatestAnalyticsData}
        userStartDate={userDetails?.analytics_data_range.start_date}
      />
      <ExportChart chartData={savingsChartData} date={formattedDate} />
      <ImportChart chartData={savingsChartData} date={formattedDate} />
      <TariffKey savingsChartData={savingsChartData} />
    </>
  );
};

export default withPageTitle('Loop Optimise Savings', SavingsContainer);
