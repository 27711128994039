/**
 * Checks if a feature is enabled.
 *
 * @param {Array<string>} enabledFeatures - The array of enabled features.
 * @param {string} feature - The feature to check.
 * @returns {boolean} - Returns true if the feature is enabled, false otherwise.
 */
const featureIsEnabled = (enabledFeatures, feature) =>
  enabledFeatures?.includes(feature) || false;

export default featureIsEnabled;
