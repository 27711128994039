/**
 * Navigation items for the application.
 *
 * This array contains the navigation items used in the application's navigation menu.
 * Each item includes the name of the navigation link, the path it points to,
 * and whether it is currently active based on the current window location.
 * Some items may also include a `hideOnDisable` property to conditionally hide the item.
 *
 * @type {Array<Object>}
 * @property {string} name - The name of the navigation item.
 * @property {string} path - The path the navigation item points to.
 * @property {boolean} active - Conditional, whether the navigation item is currently active.
 * @property {boolean} [hideOnDisable] - Whether to hide the item when disabled (optional).
 */
export default [
  {
    name: 'Today',
    path: '/app/today',
    active: window.location.pathname.includes('/app/today'),
  },
  {
    name: 'Savings',
    path: '/app/savings',
    active: window.location.pathname.includes('/app/savings'),
    hideOnDisable: true,
  },
  {
    name: 'Energy Flow',
    path: '/app/energy-flow',
    active: window.location.pathname.includes('/app/energy-flow'),
  },
  {
    name: 'Settings',
    path: '/app/settings',
    active: window.location.pathname.includes('/app/settings'),
  },
  {
    name: 'Help',
    path: '/app/help',
    active: window.location.pathname.includes('/app/help'),
  },
];
