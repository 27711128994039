import React, { useState } from 'react';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
} from 'victory';
import LoadingCard from '../../LoadingCard';
import StyledTitle from '../../Styled/StyledTitle';
import { StyledAnchor } from '../../Styled';
import InfoModal from '../../Modal/InfoModal';
import { light, white } from '../../../theme/colors';

const SystemEnergyHistoryChart = ({ data, fetchingAnalyticsData, yDomain }) => {
  // Data keys representing different categories of energy data
  const dataKeys = ['Exported', 'Imported', 'Consumption', 'Solar generation'];
  // Colors corresponding to each data key
  const colors = ['#2648b2', '#ff0085', '#3cba92', '#f7d22d'];

  // State to manage the highlighted key for emphasizing a specific data area
  const [highlightedKey, setHighlightedKey] = useState(null);
  // State to control the visibility of the info modal
  const [showInfo, setShowInfo] = useState(false);

  // Function to transform the data key name to have the first letter capitalized
  const transformDataName = name =>
    name.charAt(0).toUpperCase() + name.slice(1);

  // Handle clicks on the legend to highlight the corresponding data area
  const handleLegendClick = clickedDataKey => {
    setHighlightedKey(prevKey =>
      prevKey === clickedDataKey ? null : clickedDataKey
    );
  };

  // Prepare the data for the legend, applying highlighting based on the selected key
  const legendData = dataKeys.map((key, index) => {
    return {
      name: transformDataName(key),
      symbol: {
        fill: colors[index],
        type: highlightedKey === transformDataName(key) ? 'square' : 'none',
      },
    };
  });

  const dataAvailable = !!data?.length;

  const placeholderData = [
    { time: '00:00', earnings: 0 },
    { time: '01:00', earnings: 0 },
    { time: '02:00', earnings: 0 },
    { time: '03:00', earnings: 0 },
    { time: '04:00', earnings: 0 },
    { time: '05:00', earnings: 0 },
    { time: '06:00', earnings: 0 },
    { time: '07:00', earnings: 0 },
    { time: '08:00', earnings: 0 },
    { time: '09:00', earnings: 0 },
    { time: '10:00', earnings: 0 },
    { time: '11:00', earnings: 0 },
    { time: '12:00', earnings: 0 },
    { time: '13:00', earnings: 0 },
    { time: '14:00', earnings: 0 },
    { time: '15:00', earnings: 0 },
    { time: '16:00', earnings: 0 },
    { time: '17:00', earnings: 0 },
    { time: '18:00', earnings: 0 },
    { time: '19:00', earnings: 0 },
    { time: '20:00', earnings: 0 },
    { time: '21:00', earnings: 0 },
    { time: '22:00', earnings: 0 },
    { time: '23:00', earnings: 0 },
    { time: '00:00', earnings: 0 },
  ];

  if (!dataAvailable) {
    yDomain = [-2, 2];
  }

  return (
    <>
      {/* Info modal providing details about the chart */}
      <InfoModal
        show={showInfo}
        onClose={() => setShowInfo(false)}
        infoContent={
          <div className="pb-3">
            The chart shows the electricity exported, imported, consumed and
            generated for the date selected.
          </div>
        }
      />
      {/* Header section with the title and info button */}
      <div
        className="d-flex justify-content-between"
        style={{ marginTop: 40, marginBottom: 40 }}>
        <StyledTitle>System energy history</StyledTitle>
        <StyledAnchor onClick={() => setShowInfo(true)}>?</StyledAnchor>
      </div>
      {/* Display loading card if data is being fetched */}
      {fetchingAnalyticsData && <LoadingCard />}
      {/* Render the chart when data is available */}
      {!fetchingAnalyticsData && (
        <div>
          <VictoryChart
            domainPadding={0}
            width={648}
            height={400}
            domain={{ y: yDomain }}
            padding={{ top: 30, right: 50, bottom: 50, left: 65 }}>
            {/* Legend for data categories with click events for highlighting */}
            <VictoryLegend
              orientation="horizontal"
              gutter={20}
              style={{
                title: { fontSize: 14 },
                labels: { cursor: 'pointer' },
              }}
              data={legendData}
              events={[
                {
                  target: ['labels', 'data'],
                  eventHandlers: {
                    onClick: (_, props) => {
                      handleLegendClick(transformDataName(props.datum.name));
                    },
                  },
                },
              ]}
            />
            {/* X-axis representing time with custom tick formatting */}
            <VictoryAxis
              tickFormat={(t, index) => {
                // Display every 6th tick label for better readability
                if (index % 6 === 0) {
                  return t;
                }
                return '';
              }}
              style={{
                axis: { stroke: 'transparent' },
                ticks: { stroke: 'transparent', size: 5 },
                tickLabels: {
                  fontSize: 14,
                  padding: 5,
                  angle: -90,
                  textAnchor: 'end',
                },
                grid: { stroke: 'transparent' },
              }}
              offsetY={50}
            />
            {/* Y-axis representing energy values with custom formatting */}
            <VictoryAxis
              dependentAxis
              tickValues={dataAvailable ? undefined : [-2, -1, 0, 1, 2]}
              tickFormat={t => `${t} kWh`}
              style={{
                axis: { stroke: 'transparent' },
                ticks: { stroke: 'transparent' },
                tickLabels: {
                  fill: 'black',
                  fontSize: 14,
                  padding: 5,
                },
                grid: {
                  stroke: dataAvailable ? light : white,
                  opacity: 0.3,
                  strokeWidth: 0.5,
                  strokeDasharray: '0',
                },
                axisLabel: {
                  fontSize: 14,
                  padding: 30,
                  textAnchor: 'middle',
                  fill: 'black',
                  fontWeight: 'normal',
                },
              }}
            />

            {/* Reference line at y = 0 for baseline */}
            <VictoryLine
              style={{
                data: { stroke: dataAvailable ? light : white, strokeWidth: 1 },
              }}
              y={() => 0}
            />

            {/* Area charts for each data key representing energy flows */}
            {dataKeys.map((key, index) => (
              <VictoryArea
                key={key}
                name={key}
                data={dataAvailable ? data : placeholderData}
                x="time"
                y={key}
                style={{
                  data: {
                    fill: colors[index],
                    fillOpacity:
                      highlightedKey === null ||
                      highlightedKey === transformDataName(key)
                        ? 0.2
                        : 0.1, // Reduce opacity for non-highlighted areas
                    stroke: colors[index],
                    strokeWidth:
                      highlightedKey === null ||
                      highlightedKey === transformDataName(key)
                        ? 3
                        : 0, // Only show line for highlighted areas
                  },
                }}
              />
            ))}
            {!dataAvailable && (
              <>
                <VictoryLabel
                  textAnchor="middle"
                  style={{ fontSize: 15, fontWeight: 'bold' }}
                  x={'50%'}
                  y={190}
                  text="Not enough data to show System energy history."
                />
              </>
            )}
          </VictoryChart>
        </div>
      )}
    </>
  );
};

export default SystemEnergyHistoryChart;
