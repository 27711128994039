import React, { useContext, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import LoopSpinner from '../../ui/LoopSpinner';
import { onboardingPropTypes } from '../../propTypes';
import { OnboardingContext } from '../../context/OnboardingContext';
import { getUnit } from '../../utils/dataUtils';
import { createUserTrace } from '../../api/Optimise';
import { SpacerHr, StyledFormContainer } from '../../ui/Styled';
import InverterDetails from './EquipmentDetails/InverterDetails';
import BatteryDetails from './EquipmentDetails/BatteryDetails';
import {
  assembleHardwareDetails,
  G99_DISCHARGE_CAP,
} from '../../utils/assembleHardwareDetails';

const EquipmentDetailsContainer = () => {
  const history = useHistory();
  const {
    inverterManufacturer,
    setProgressPercentage,
    inverter,
    setInverter,
    inverterUnitList,
    batteryUnitList,
    batteries,
    setHardwareDetails,
  } = useContext(OnboardingContext);

  const validationSchema = Yup.object({
    inverterManufacturer: Yup.string()
      .nullable()
      .trim()
      .required('Please select your inverter manufacturer'),
    inverterUnitName: Yup.string()
      .nullable()
      .trim()
      .required('Please select your inverter model'),
    batteries: Yup.array().of(
      Yup.object().shape({
        manufacturer: Yup.string()
          .nullable()
          .trim()
          .required('Please select your battery manufacturer'),
        model: Yup.string()
          .nullable()
          .trim()
          .required('Please select your battery model'),
        quantity: Yup.number()
          .nullable()
          .required('Please select battery model quantity')
          .min(1, 'Quantity must be at least 1')
          .max(9, 'Quantity must be less than 9'),
      })
    ),
  });

  const onSubmit = ({ inverterUnitName, batteries }) => {
    const selectedInverter = getUnit(inverterUnitList, inverterUnitName);
    setInverter(selectedInverter);

    const selectedBatteries = batteries.map((battery, index) => {
      const selectedBattery = getUnit(batteryUnitList[index], battery.model);
      return {
        ...battery,
        ...selectedBattery,
      };
    });

    const hardwareDetails = assembleHardwareDetails(
      selectedInverter,
      selectedBatteries
    );

    setHardwareDetails(hardwareDetails);

    createUserTrace('Equipment', {
      battery_capacity: hardwareDetails.batteryCapacity,
      battery_degradation_cost: hardwareDetails.batteryDegradationCost,
      battery_efficiency: hardwareDetails.batteryEfficiency,
      battery_max_charge_power: hardwareDetails.batteryMaxChargePower,
      battery_max_discharge_power: hardwareDetails.batteryMaxDischargePower,
      hardware_details: {
        batteries: hardwareDetails.batteryDetails,
        inverter: hardwareDetails.inverterDetails,
      },
    });

    if (hardwareDetails.batteryMaxChargePower > G99_DISCHARGE_CAP) {
      history.push('/onboarding/export-limit');
    } else {
      const inverterManufacturerSlugHyphenated = inverterManufacturer
        .toLowerCase()
        .replace(/\s/g, '-');
      history.push(
        `/onboarding/connection/${inverterManufacturerSlugHyphenated}`
      );
    }
  };

  useEffect(() => {
    setProgressPercentage(70);
  }, []);

  return (
    <>
      <h1 className="mb-3">Your inverter and battery details</h1>
      <div>
        <p>Please select your inverter and battery details.</p>
        <StyledFormContainer>
          <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
              inverterManufacturer,
              inverterUnitName: inverter?.unit_name,
              batteries,
            }}
            validateOnChange>
            {({ handleSubmit, isValid, isSubmitting, values, touched }) => (
              <Form onSubmit={handleSubmit}>
                <InverterDetails />
                <BatteryDetails values={values} />
                <SpacerHr label="or" block />
                <Form.Group className="mt-2 mb-1">
                  {!isSubmitting ? (
                    <Button
                      variant="primary"
                      disabled={!isValid || isSubmitting}
                      className="mb-0"
                      block
                      type="submit">
                      Continue
                    </Button>
                  ) : (
                    <LoopSpinner />
                  )}
                </Form.Group>
              </Form>
            )}
          </Formik>
        </StyledFormContainer>
      </div>
    </>
  );
};

EquipmentDetailsContainer.propTypes = {
  ...onboardingPropTypes,
};

export default EquipmentDetailsContainer;
