import { faPauseCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';

const StyledAlert = styled(Alert)`
  font-family: 'Poppins';
  font-weight: bold;
  margin-top: 26px;
  box-shadow: none;
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const DisabledWarning = () => (
  <StyledAlert variant="warning">
    <StyledIcon icon={faPauseCircle} size="xl" />
    <div>Loop Optimise is off. To turn it back on, go to Settings</div>
  </StyledAlert>
);

export default DisabledWarning;
